.container {
  background-color: #fdfffc;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0.0, 0.0, 0.0, 0.05);
}

.padding {
  padding: 16px;
}

.container h1 {
  color: #0082ff;
  font-size: 1.5em;
  margin: 0px 0px 0.25em 0px;
}

@media (prefers-color-scheme: dark) {
  .container {
    background-color: #272725;
  }

  .container h1 {
    color: #ead777;
  }
}
