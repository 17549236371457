.button {
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.normalSize {
  padding: 8px 16px 8px 16px;
}

.smallSize {
  padding: 4px 8px 4px 8px;
}

.button:hover {
  filter: brightness(0.95);
}

.button:active {
  filter: brightness(0.9);
}

.button:disabled {
  opacity: 0.5;
}

.normalUse {
  background-color: #eaedf0;
}

.primaryUse {
  background-color: #0082ff;
  color: #ffffff;
}

@media (prefers-color-scheme: dark) {
  .button {
    color: #ffffff;
  }

  .normalUse {
    background-color: #656560;
  }

  .primaryUse {
    background-color: #9c8a2a;
  }
}
