.navBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100%;
  width: 100%;
  padding: 0px;
}

.title {
  flex: 1;
  font-weight: 800;
  font-size: 1.5em;
}

.navBar ul {
  padding: 0px;
  margin-bottom: 0px;
}

.navBar li {
  flex: 1;
  list-style-type: none;
  text-align: center;
  font-size: 1.25em;
  display: inline-block;
  padding: 0px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.navBar li a {
  display: block;
  text-decoration: none;
  color: #140A00;
  background-color: #fdfffc;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0.0, 0.0, 0.0, 0.05);
}

.navBar li a:hover {
  transition: all 0.2s ease;
  background-color: #eff1ee;
}

@media (prefers-color-scheme: dark) {
  .navBar li a {
    color: #f8f8f9;
    background-color: #272721;
  }

  .navBar li a:hover {
    transition: all 0.2s ease;
    background-color: #494945;
  }
}
