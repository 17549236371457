body {
  background-color: #f8f8f9;
  color: #020100;
  font-family: 'Open Sans', sans-serif;
}

.app {
  margin: 64px;
}

@media screen and (max-width: 600px) {
  .app {
    margin: 8px;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #080807;
    color: #f8f8f9;
  }
}
