.footer {
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0.0, 0.0, 0.0, 0.05);
    padding: 0px;
    font-size: smaller;
}

.footer ul {
    padding: 0px;
}

.footer li {
    position: relative;
    display: inline;
    white-space: pre;
}

.footer li a {
    color: #140A00;
    text-decoration: none;
}

.footer li a:hover {
    text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
    .footer li::before {
        color: #f8f8f9;
    }

    .footer li a {
        color: #f8f8f9;
    }
}