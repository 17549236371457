.input {
  background-color: #fdfffc;
  font-size: 1em;
  border: none;
  border-radius: 8px;
  transition: all 0.2s ease;
  padding: 8px;
  width: calc(100% - 16px);
  border: solid 1px #888888;
  outline: none;
  font-family: inherit;
}

.input:disabled {
  background-color: #eeefed;
}

.input:focus {
  box-shadow: 0px 0px 8px rgba(68, 163, 255, 0.5);
}

@media (prefers-color-scheme: dark) {
  .input {
    background-color: #272721;
    color: #f8f8f9;
  }

  .input:disabled {
    background-color: #272721;
    opacity: 0.5;
  }

  .input:focus {
    box-shadow: 0px 0px 8px rgba(255, 255, 253, 0.5);
  }
}
