.example {
  color: #93999d;
  font-style: italic;
  margin-bottom: 0px;
}

.highlight {
  background-color: rgb(255, 254, 245);
}

.definition {
  margin: 0;
}

.link {
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  .highlight {
    background-color: #3e3e34;
  }
}
