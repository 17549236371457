.actions button {
  margin-right: 8px;
}

.options {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 0.8em;
}

.creator {
  color: #93999d;
}
